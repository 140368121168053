import { LoaderFunctionArgs } from "react-router-dom";

export const loader = async ({ params }: LoaderFunctionArgs) => {
    return {
        data: 123,
        type: "templates_root",
        id: params.id,
    };
};

export default loader;
