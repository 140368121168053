import React, { useCallback, useContext, useEffect, useState } from "react";
import { KeycloakInitOptions } from "keycloak-js";
import { createContext, ReactNode } from "react";
import keycloak from "../keycloak";
import { KeycloakContextType } from "../types/KeycloakContextType";

/**
 * Hook to create a context instance for Keycloak, which automatically initializes upon mount.
 *
 * @returns Constructed Keycloak context instance with state
 */
const useKeycloakAsContext: () => KeycloakContextType = () => {
    const [keycloakInstance] = useState(keycloak);
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const init = useCallback(
        (options?: KeycloakInitOptions) => {
            setLoading(true);
            keycloakInstance
                .init({
                    onLoad: "check-sso",
                    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
                    ...options,
                })
                .then(() => {
                    setInitialized(true);
                    setLoading(false);
                })
                .catch(() => {
                    alert(
                        "Er is een fout opgetreden, probeer het later opnieuw"
                    );
                });
        },
        [setLoading, keycloakInstance, setInitialized]
    );

    // Reset initialization flag when the instance changes
    useEffect(() => {
        if (!keycloakInstance) return;
        setInitialized(false);
    }, [keycloakInstance, setInitialized]);

    // Init when not initialized
    useEffect(() => {
        if (!initialized && !loading) {
            init();
        }
    }, [initialized, init, loading]);

    return {
        keycloak: keycloakInstance,
        authenticated: keycloakInstance.authenticated ?? false,
        initialized,
        loading,
    };
};

export const keycloakContext = createContext<KeycloakContextType>(
    {} as KeycloakContextType
);

/**
 * Hook to obtain the Keycloak context within components
 * @returns Context-value for Keycloak
 */
export const useKeycloak = () => useContext(keycloakContext);

/**
 * Provider for Keycloak
 *
 * @param children Children to be rendered within this provider
 * @returns Provider linked to a populated context instance
 */
export const KeycloakProvider = ({ children }: { children: ReactNode }) => {
    const keycloak = useKeycloakAsContext();
    const Provider = keycloakContext.Provider;
    return <Provider value={keycloak}>{children}</Provider>;
};

export default KeycloakProvider;
