import React, { useState } from "react";
import { DataGrid as MuiDataGrid, GridRowParams } from "@mui/x-data-grid";
import { DataGridProps } from "./DataGrid.types";
import { StyledBox } from "./DataGrid.styles";

export const DataGrid = ({ columns, rows, onRowClick }: DataGridProps) => {
    const [pageSize, setPageSize] = useState(10);
    const handlePageSize = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const handleRowClick = (row: GridRowParams) => {
        if (onRowClick) onRowClick(row.id);
    };

    return (
        <StyledBox>
            <MuiDataGrid
                rows={[...rows]}
                columns={[...columns]}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 50, 100]}
                onPageSizeChange={handlePageSize}
                onRowClick={handleRowClick}
                experimentalFeatures={{ newEditingApi: true }}
                disableColumnMenu
                isCellEditable={() => false}
                pagination
            />
        </StyledBox>
    );
};

export default DataGrid;
