import React from "react";
import { useLoaderData } from "react-router-dom";

export const TemplateCreate = () => {
    const data = useLoaderData();

    return (
        <div>
            TemplateCreate <pre>{JSON.stringify(data)}</pre>
        </div>
    );
};

export default TemplateCreate;
