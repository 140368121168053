import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./i18n.config";

function App() {
    return (
        <div>
            <CssBaseline />
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
