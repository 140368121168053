import React from "react";
import { IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBarProps } from "./AppBar.types";
import { AppBar as StyledAppbar } from "./AppBar.styles";
import AccountMenu from "../AccountMenu/AccountMenu";
import { useTranslation } from "react-i18next";

export const AppBar = ({ onClick, open }: AppBarProps) => {
    const { t } = useTranslation();

    return (
        <StyledAppbar position="fixed" open={open}>
            <Toolbar>
                <IconButton onClick={onClick} edge="start" color="inherit">
                    <MenuIcon />
                </IconButton>

                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    {t("common:title")}
                </Typography>

                <AccountMenu />
            </Toolbar>
        </StyledAppbar>
    );
};

export default AppBar;
