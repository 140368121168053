import React from "react";
import { Link } from "react-router-dom";
import DataGrid from "src/components/organisms/DataGrid/DataGrid";

const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
        field: "firstName",
        headerName: "First name",
        width: 150,
        editable: true,
    },
    {
        field: "lastName",
        headerName: "Last name",
        width: 150,
        editable: true,
    },
    {
        field: "age",
        headerName: "Age",
        type: "number",
        width: 110,
        editable: true,
    },
];

const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
    { id: 10, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 15, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 16, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 17, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 18, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 19, lastName: "Roxie", firstName: "Harvey", age: 65 },
    { id: 24, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 25, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 26, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 27, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 28, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 29, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

export const TemplateRoot = () => {
    return (
        <div>
            <DataGrid columns={columns} rows={rows} />
            <Link to="/templates/create">Aanmaken..</Link>
        </div>
    );
};

export default TemplateRoot;
