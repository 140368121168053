import React from "react";
import { List } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CampaignIcon from "@mui/icons-material/Campaign";
import { MainMenuProps } from "./MainMenu.types";
import MenuItem from "src/components/molucules/MenuItem/MenuItem";
import { useTranslation } from "react-i18next";

export const Layout = ({ open }: MainMenuProps) => {
    const { t } = useTranslation();

    const menu = [
        {
            text: t("common:menu.campaigns"),
            href: "/campaigns",
            icon: <CampaignIcon />,
        },
        {
            text: t("common:menu.templates"),
            href: "/templates",
            icon: <ChatIcon />,
        },
    ];

    return (
        <List>
            {menu.map((item) => (
                <MenuItem
                    href={item.href}
                    hideText={open}
                    icon={item.icon}
                    text={item.text}
                    key={item.text}
                />
            ))}
        </List>
    );
};

export default Layout;
