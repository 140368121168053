import React from "react";
import { Link, useLoaderData, useParams } from "react-router-dom";

export const TemplateDetail = () => {
    const data = useLoaderData();
    const { id } = useParams();

    return (
        <div>
            TemplateDetai;{" "}
            <pre>
                {JSON.stringify(data)} {id}
            </pre>
            <Link to="/templates/create">Aanmaken..</Link>
        </div>
    );
};

export default TemplateDetail;
